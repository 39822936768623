import React from "react";
import IndexRouter from "./router/IndexRouter";

export default function App() {
  return (

      <IndexRouter></IndexRouter>

  );
}
